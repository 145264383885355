<template>
	<div class="row">
		<div class="col-12" v-if="!model_id_local">
			<h2>{{ $t("courrier_type.generation") }}</h2>
			<div>
				<label for="model_type">{{ $t('courrier_type.placeholder_type') }}</label>
				<e-select
		            v-model="model_selected"
		            id="models"
		            track-by="model_id"
		            label="model_label"
		            class="primary"
		            :options="models"
		            :searchable="true"
		            :show-labels="false"
		            :allow-empty="false"
					:placeholder="$t('courrier_type.placeholder_type')"
		        >
		            <template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
		            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
		        </e-select>
		    </div>

	        <div class="mt-3">
	    		<label>{{ $t('courrier_type.model_label') }}</label>
				<input type="text" class="form-control" :placeholder="$t('courrier_type.model_label')" v-model="model_label">
			</div>

	        <div v-if="model_selected" class="text-center">
	            <b-button pill variant="primary" class="mt-3" @click.prevent="addModel"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("courrier_type.generer") }}</b-button>
	        </div>
	    </div>
	    <div v-else class="col">
	    	<Edit ref="edit" :modelable_id="horse_id" :modelable_type="model_type_generated" :model_id="model_id_local" class_perso="_" :preview_pdf.sync="preview_pdf" :save_pdf="false"/>
	    </div>
	    <div v-if="base64" class="col-6">
			<iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
	    </div>
	</div>
</template>

<script type="text/javascript">
    import ModelMixin from "@/mixins/Model.js";
	import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'tiersCourrierTypeEdit',
		mixins: [ModelMixin, Shutter],
		props: ['model_id', 'horse_id', 'modeltype_template'],
		data () {
			return {
				models: [],
				model_label: "",
				model_selected: null,
				processing: null,
				model_id_local: null,
				modeltype_template_local: null,
				base64: null,
				preview_pdf: true
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(!this.model_id) {
					this.models = await this.loadAllModel(0, ['horse_envoie_infos'])
				} else {
					this.model_id_local = this.model_id
					this.modeltype_template_local = this.modeltype_template
				}
			},

			async addModel() {
				this.processing = true
				if(this.model_label !== "") {
					const model = await this.createModel(this.horse_id, this.model_selected.type.modeltype_id, this.model_label, this.model_selected.type.modeltype_template)
					await this.cloneModel(this.model_selected.model_id, model.model_id)
					await this.generateCourrier(model.model_id, this.model_selected.type.modeltype_template, this.horse_id)
					this.modeltype_template_local = this.model_selected.type.modeltype_template
					this.model_id_local = model.model_id
					this.processing = false
					this.shutterPanel().reload('courrier-type')
				}
				else {
					this.failureToast('toast.no_model_label')
					this.processing = false
				}
			},

			async previewCourrier() {
				await this.$refs.edit.saveForm()
				this.base64 = await this.printCourrier(this.model_id_local, '', true)
			}
		},

		computed: {
			model_type_generated() {
				if(this.modeltype_template_local == 'horse_envoie_infos')
					return 'horse_envoie_infos_generated'
				return this.modeltype_template_local
			}
		},

		watch: {
			preview_pdf(val) {
				this.previewCourrier()
			}
		},

		components: {
			Edit : () => import('@/components/Model/Edit')
		}
	}

</script>